const CourseSearchBox = ({
    selectedCourses,
    clearAllCourse,
    onRemoveCourse,
    onFoucusCourse,
    coursePlaceholder,
    courseInput,
    handleKeyDown,
    searchCourses,
    onFoucusCourseArrow,
    courseError,
    wrapperRef,
    spellCheck,
    disciplines,
    onSelectDisciplineWithOpenSpecializations,
    setCourseInput,
    onArrowDisciplineSpecializations,
    onClickCourseModal,
    onFoucusOutCourse
}) => {
    let selectedSpecializations = selectedCourses.filter(element => element.type !== 'Discipline');
    return (
        <div className="searchBox">
            {/* {selectedCourses.length >= 1 && (
                <button
                    className="unselectBtn"
                    onClick={(e) => {
                        clearAllCourse();
                    }}
                >
                    x
                </button>
            )} */}
            <div className="custom-dropdown">
                    {/* Can be used if multi select is enabled and we want to display multiple selected items */}
                <div className="tcf-tag searchTagWrap">
                    {selectedSpecializations.length < 4
                        ? selectedSpecializations.map((item, index) => {
                            if (index <= 2) {
                                return (
                                    <div key={index} className="searchTag">
                                        {item.name}
                                        <button
                                            className="unselectBtn"
                                            onClick={(e) => {
                                                onRemoveCourse(index, item);
                                            }}
                                        >
                                            x
                                        </button>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })
                        : selectedSpecializations.map((item, index) => {
                            if (index < 2) {
                                return (
                                    <div key={index} className="searchTag">
                                        {item.name}
                                        <button
                                            className="unselectBtn"
                                            onClick={(e) => {
                                                onRemoveCourse(index, item);
                                            }}
                                        >
                                            x
                                        </button>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}
                    {selectedSpecializations.length >= 4 && (
                        <div className="searchTag" style={{ paddingRight: 5 }}>
                            <a href
                                onClick={(e) => {
                                    onFoucusCourse()
                                }}
                            >
                                view More
                            </a>
                        </div>
                    )}
                </div>
                <div className="btnWrap">
                    <input
                        id="course_input"
                        placeholder={coursePlaceholder}
                        type="text"
                        autoComplete={"off"}
                        spellCheck={courseInput}
                        className="form-control"
                        value={courseInput}
                        onFocus={onFoucusCourse}
                        onBlur={onFoucusOutCourse}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => searchCourses(e.target.value)}
                    />
                    <i
                        className="fa fa-angle-down"
                        onClick={onFoucusCourseArrow}
                    ></i>
                    {courseError && (
                        <p className={"text-danger"}>Course is not Selected</p>
                    )}
                </div>
                <div
                    id="couresSearchOpen"
                    ref={wrapperRef}
                    className="custom-dropdown-menu spellCheck-search" >
                    {spellCheck?.length && courseInput !== spellCheck ? <div className="coures-spellCheck-Open">
                        <div className="spellCheck-list text-left">
                            <p style={{ paddingTop: "5px", marginBottom: "0px" }}><b>Do you mean?</b></p>
                            <button type="button" className="word-confirm" onClick={() => setCourseInput(spellCheck)}>{spellCheck}</button>
                        </div>

                    </div> : null}
                </div>
                <div
                    id="couresOpen"
                    className="custom-dropdown-menu"
                >
                    <div className="regionFlex regionCourseFlex">
                        <div className="left" >
                            <p style={{ paddingTop: "5px", margin: "0px !important" }}><b>Type Course or Select Discipline</b></p>
                            <ul>
                                {disciplines?.map((item, index) => {
                                    return (
                                        <button
                                            key={index}
                                            className='disciplines_course'
                                            onClick={(e) => {
                                                onSelectDisciplineWithOpenSpecializations(item);
                                            }}
                                        >
                                            <span
                                                href
                                                key={item.id}
                                                className="searchItemBtn btn dropdown-item"
                                            >
                                                <a
                                                    className={item.checked === true ? "active" : "searchItemBtn btn dropdown-item"}
                                                    href
                                                >
                                                    {item.name}
                                                </a>
                                                <a
                                                    href
                                                    onClickCapture={(e) => {
                                                        e.stopPropagation()
                                                        onArrowDisciplineSpecializations(item);
                                                        onClickCourseModal();
                                                    }}
                                                >
                                                    <i className="fa fa-angle-right"></i>
                                                </a>
                                            </span>
                                        </button>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseSearchBox;